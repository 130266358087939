<template>
  <div class="perfectInfo">
    <!-- 个人信息 -->
    <div class="IDinformation">
      <div class="header-title">
        <p>申请开店 - 个人信息</p>
      </div>
      <Steps :steps="0" :type="stepType" />
      <div class="upload-content l-flex-allcenter">
        <a-spin :spinning="idCardLoading && uploadLabel === 'idCardFrontUrl'">
          <img class="card" :class="form.idCardFrontUrl && 'hasUrl'" :src="form.idCardFrontUrl || require('@/assets/images/card/front.png')" @click="uploadIdCard('idCardFrontUrl')">

          <!-- <div class="upload" @click="uploadIdCard('idCardFrontUrl')"
            :style="{backgroundImage: form.idCardFrontUrl && `url(${form.idCardFrontUrl})`}">
            <a-icon v-if="!form.idCardFrontUrl" type="plus"></a-icon>
          </div>
          <img src="@/assets/images/card/+.png" alt=""> -->
          <p class="note">*请上传身份证头像面</p>
        </a-spin>
        <a-spin :spinning="idCardLoading && uploadLabel === 'idCardBackUrl'" style="margin-left: 64px">
          <img class="card" :class="form.idCardBackUrl && 'hasUrl'" :src="form.idCardBackUrl || require('@/assets/images/card/back.png')" @click="uploadIdCard('idCardBackUrl')">

          <!-- <div class="upload" :style="{backgroundImage: form.idCardBackUrl && `url(${form.idCardBackUrl})`}"
            @click="uploadIdCard('idCardBackUrl')">
            <a-icon v-if="!form.idCardBackUrl" type="plus"></a-icon>
          </div> -->
          <p class="note">*请上传身份证国徽面</p>
        </a-spin>
      </div>
      <div class="information">
        <div class="list">
          <span class="red">*</span>头像: &nbsp;
          <div class="container">
          <VueCropper class="cropper" v-if="idCardHeadSwitch" ref="cropper" :img="form.idCardFrontUrl" :info="true"
            :autoCrop="options.autoCrop" :autoCropWidth="options.autoCropWidth" :autoCropHeight="options.autoCropHeight"
            :fixedBox="options.fixedBox" full @realTime="realTime">
          </VueCropper>

          <img v-if="!idCardHeadSwitch" :src="form.idCardHeadUrl" alt="" style="margin-right: 16px;width: 180px">
          <a-space :size="12" style="margin-top: 12px;">
            <a-button @click="tailor">{{ idCardHeadSwitch ? '裁剪' : '重新裁剪' }}</a-button>
            <a-button type="primary" :loading="loadingHeader" @click="uploadIdCard('customUpload')">自定义上传</a-button>
          </a-space>
          </div>
        </div>
        <div class="list">
          <span class="red">*</span>姓名: &nbsp;
          <a-input class="info-input" v-model="bioassay.name" placeholder="请输入姓名"/>
        </div>
        <div class="list">
          <span class="red">*</span>民族: &nbsp;
          <a-select placeholder="请选择民族" class="info-input" :value="bioassay.race" @select="bioassay.race = $event">
            <a-select-option v-for="item in nation" :key="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </div>
        <div class="list">
          <span class="red">*</span>性别: &nbsp;
          <a-select class="info-input" placeholder="请选择性别" :value="bioassay.gender" @select="bioassay.gender = $event">
            <a-select-option :value="1">男</a-select-option>
            <a-select-option :value="0">女</a-select-option>
          </a-select>
        </div>
        <div class="list">
          <span class="red">*</span>地址: &nbsp;
          <a-input class="info-input" placeholder="请输入地址" v-model="bioassay.address"></a-input>
        </div>
        <div class="list">
          <span class="red">*</span>证件号: &nbsp;
          <a-input class="info-input" v-model="bioassay.id" placeholder="证件号" :maxLength="18" />
        </div>
        <div class="list">
          身份证有效期: &nbsp;
          <div class="info-input" style="text-align: left">
            <!-- <a-range-picker @change="onIndateChange" style="width: 400px" /> -->
            <a-space>
              <a-date-picker v-model="form.idCardStartDate" placeholder="开始日期" :disabled-date="disabledStartDate">
                </a-date-picker>
                至
                <a-date-picker v-if="!long" v-model="form.idCardEndDate" placeholder="结束日期" :disabled-date="disabledEndDate">
                </a-date-picker>
                <a-checkbox v-model="long" @change="form.idCardEndDate = undefined">长期
                </a-checkbox>
            </a-space>
          </div>
        </div>
      </div>

      <div class="tax">
        <div class="tax-input">
          <div style="line-height: 30px;padding: 0 10px">
            关联公司: &nbsp;
            <div class="company">
              <SearchSelect placeholder="" class="SearchSelect" :type="false" :list="company" :focu="false"
              @search="onSearch" @select="getID"></SearchSelect>
            </div>
          </div>
        </div>
        <div class="tax-text">
          <p>
            <!-- <span class="tax-text"
                >6.
                搜索选择关联的买家公司，便于运营与买家联系，由买家沟通卖家加快注册审核。也可以不关联，由平台直接联系卖家，同时不归属集团</span
              > -->
          </p>
          <p>
            <!-- <span class="tax-text"
                >7.
                选择初始注册税区，即第一个申请的税区，默认取系统默认税区，即系统的总部税区</span
              > -->
          </p>
        </div>
      </div>
      <div class="tax">
        <div class="tax-input">
          <div style="line-height: 30px;padding: 0 10px">
            税区选择: &nbsp;
            <div class="company">
              <a-select class="serve-select" style="width: 230px" :default-value="regionName[0]" placeholder="请选择税区" @change="region">
                <a-select-option v-for="item in regionName" :key="item.id">
                  {{ item.regionName }}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </div>

      <!-- 银行验证 -->
      <div class="bankverify">
        <div class="banktitle">
          <p class="banktitle-left">银行验证</p>
          <p class="banktitle-right">
            <!-- 8.
              通过浙商鉴权开通浙商网关支付，如需要开通平安银行支付，则需要在个体户办理成功后，通过卖家中心进行平安鉴权开户 -->
          </p>
        </div>
        <a-row style="padding: 10px">
          <a-col :span="24" style="padding: 10px 100px 0">默认使用网关支付进行快捷开店，若需要支持平安银行的余额支付，可以前往卖家中心-支付管理进行后续开通。</a-col>
        </a-row>
        <div class="verification">
          <p>
            &nbsp;&nbsp;&nbsp;<span class="red">*</span>银行账号: &nbsp;
            <a-input class="info-input3" :maxLength="30" v-model="bankAccount"
              @input="bankAccount=$event.target.value.replace(/[^\d]/g,'')" placeholder="请输入银行账号"
              @blur="blur(bankAccount)" />
          </p>
          <div class="carefully">
            <span>建议使用一类卡作为收款账号，因各银行限制，二类卡一天只能收1万元，超额打款会导致收款失败！</span>
          </div>
          <p class="p-bank-bind">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="red">*</span>开户行: &nbsp;
            <a-select class="p-bank" :value="openBank" placeholder="请选择银行" @change="BankChange">
              <a-select-option v-for="item in Bank" :key="item.bankCode">
                {{ item.bankName }}
              </a-select-option>
            </a-select>
            &nbsp;&nbsp;
            <a-cascader class="p-site" :allowClear="false" :options="city" :field-names="{
                value: 'key',
                label: 'label',
                children: 'children'
              }" placeholder="请选择地址" @change="cascader" v-model="cascaderValue">
            </a-cascader>&nbsp;&nbsp;
            <!-- <SearchSelect :btnType="false" placeholder="请输入开户行名称进行查询" class="SearchSelect search-bank-v"
              :type="['branchNo','branchName']" :list="bankSlip" @select="getBanknum" @search="SearchBankNum">
            </SearchSelect> -->
          </p>
          <p>
            &nbsp;开户行名称: &nbsp;
            <a-input class="info-input3" v-model="bankName" placeholder="请输入开户行名称" />
          </p>
          <p>
            &nbsp;开户行行号: &nbsp;
            <a-input class="info-input3" v-model="bankNumber" placeholder="请输入开户行行号" />
          </p>
          <p>
            <span class="red">*</span>预留手机号:&nbsp;
            <a-input class="info-input3" v-model="bankReservedMobile" placeholder="请输入银行预留手机号" :maxLength="11"
              @input="bankReservedMobile=$event.target.value.replace(/[^\d]/g,'')" />
          </p>
        </div>
      </div>

      <div class="next">
        <a-button style="width: 100px" type="primary" @click="next" :loading="loading"><span v-if="!loading">提交</span>
        </a-button>
      </div>
      <div id="canve" class="canve" ref="img">
        <img :src="idNoWHeadUrl" alt="" @click="canvess" />
      </div>
    </div>
    <!-- 上传img -->
    <input v-show="false" id="addImage" ref="addImage" class="upload" type="file" name="addImage" multiple="multiple"
      accept="image/png,image/jpeg,image/gif,image/jpg" @change="getImgFile" />
  </div>
</template>

<script>
import {
  addindividual,
  selectpurchaser,
  selectBanks,
  selectregion,
  personal,
  cardOcr,
  cardBackOcr
} from '../../api/seller'
import { nation } from "../../api/utils";
import { VueCropper } from 'vue-cropper'
import Steps from './component/steps.vue'
import bank from '../../utils/banks'
import SearchSelect from '../../components/SearchSelect.vue'
import { uploadPhoto } from '../../api/utils'
import Html2canvas from 'html2canvas'
import { convertCanvasToImage } from '../../utils/canves'
import city from '../../utils/city'
import { branch } from '@/api/public'
import moment from 'moment'
var myreg = /^1[3-9][0-9]{9}$/
export default {
  components: {
    Steps,
    SearchSelect,
    VueCropper
  },
  data() {
    return {
      nation: nation(),
      steps: 1, //进度条
      stepType: 1,
      company: [], //关联公司
      commercialName: '', //关联公司名字
      purchaserId: '', //关联采购商ID
      regionName: [], //注册祱区
      // registrationPrefix: '', //公司前缀
      // registrationSuffix: '', //公司后缀
      // SearchValue: '', //搜素结果
      cascaderValue: [], //  城市值

      legalName: '', //法人姓名
      idCard: '', //法人身份证号
      bankAccount: '', //银行账号
      bankReservedMobile: '', //银行预留手机号
      idCardFrontUrl: '', //身份证正面地址
      idCardBackUrl: '', //身份证反面地址
      idCardHeadUrl: '', //省份证头像地址
      idNoWHeadUrl: '',
      recordVedioUrl: '', //视频录像地址
      regionId: '', //祱区Id
      taxRegionName: '', //税区名称
      bankSlip: [],
      bankNum: {
        cityCode: [],  // 市级编码
      },              //  获取银行行号参数
      city,
      bankName: '', // 开户行名称
      openBank: undefined, //开户行查询
      bankNumber: '', // 开户行行号
      Searchlist: { placeholder: '' },
      bioassay: {
        //活体检测结果
        id: '', //身份证号
        name: '', //姓名
        gender: undefined, // 性别[男/女]
        address: '', //身份证住址
        race: undefined, //民族
        issuedBy: '', //身份证发证机构
        validDateStr: '', //有效期格式：2000-01-01
        idFront: '', // 身份证正面照片B64字符串
        idBack: '', //身份证背面照片B64字符串
        imgUrl: '', //认证照片下载url(24小时内有效)
        videoUrl: '' //活体检测下载url(24小时内有效)
      },
      Bank: [], //银行列表
      loading: false, //loading
      uploadLabel: '',
      form: {
        idCardBackUrl: undefined,
        idCardFrontUrl: undefined,
        idCardHeadUrl: undefined,
        idCardStartDate: undefined,
        idCardEndDate: undefined
      },
      options: {
        autoCrop: true,
        autoCropWidth: 80,
        autoCropHeight: 90
      },
      idCardHeadSwitch: true,
      idCardHeadBase64: '',
      loadingHeader: false,
      idCardLoading: false,
      long: false, // 身份证长期
    }
  },
  created() {
    this.getface()
  },
  mounted() {
    selectBanks().then((res) => {
      this.Bank = res.data
    })
  },
  watch: {
    idNoWHeadUrl(val) {
      if (val) {
        this.$nextTick(() => {
          this.canvess()
        })
      }
    }
  },
  methods: {
    region(id, name) {
      this.regionId = id + ''
      this.taxRegionName = name.componentOptions.children[0].text
      if (this.taxRegionName.indexOf('不进行工商注册') > -1) {
        this.stepType = 2
      } else {
        this.stepType = 1
      }
    },
    // bs64转File
    dataURLtoFile(dataurl, fileName) {
      if (!dataurl) return
      dataurl = dataurl.replace(/\n/g, '')
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], fileName, { type: mime })
    },

    // s祱区id获取
    onSearch(value) {
      if (value) {
        selectpurchaser({ commercialName: value }).then((res) => {
          this.company = res.data
        })
      } else {
        this.$message.error('请填写关联公司')
      }
    },
    getID(d) {
      this.commercialName = d.commercialName
      this.purchaserId = d.id
    },
    blur() {
      if (bank(this.bankAccount).bankName) {
        this.Bank.forEach((item) => {
          item.bankName === bank(this.bankAccount).bankName &&
            (this.openBank = item.bankCode)
        })
      } else if (!this.bankAccount) {
        this.$message.error('银行卡不能为空')
      } else {
        this.openBank = undefined
      }
    },
    BankChange(openCode) {
      this.openBank = openCode
      this.cascaderValue = []
    },
    canvess() {
      Html2canvas(this.$refs.img).then((canvas) => {
        var shareImg = convertCanvasToImage(canvas)
        shareImg.style.width = '100%'
        shareImg.style.height = '100%'
        uploadPhoto(
          this.dataURLtoFile(
            'data:image/jpg;base64,' + shareImg.src.split('base64,')[1],
            'idCardHeadUrl.jpg'
          ),
          (url) => {
            this.idCardHeadUrl = url
          }
        )
      })
    },
    SearchBankNum(e) {
      const config = {
        cityCode: this.cascaderValue[1],
        keywords: e,
        bankCode: this.openBank
      }
      branch(config).then(res => {
        this.bankSlip = res.data.branchList
      })
    },
    getBanknum(e) {
      this.bankName = e.branchName
      this.bankNumber = e.branchNo
    },
    // 获取活体检测结果
    getface() {
      selectregion({}).then((res) => {
        this.regionName = res.data
      })
      selectBanks({}).then((res) => {
        this.Bank = res.data
      })
      // face({ bizNo: this.$route.query.bizNo }).then((res) => {
      //   if (res.code == 200) {
      //     this.bioassay.id = res.data.id
      //     this.bioassay.name = res.data.name
      //     this.bioassay.gender = res.data.gender === '男' ? 1 : 0
      //     this.bioassay.address = res.data.address
      //     this.bioassay.race = res.data.race
      //     this.bioassay.issuedBy = res.data.issuedBy
      //     this.bioassay.validDateStr = res.data.validDateStr
      //     this.bioassay.imgUrl = res.data.imgUrl
      //     this.bioassay.videoUrl = res.data.videoUrl
      //     this.idNoWHeadUrl =
      //       'data:image/jpg;base64,' + res.data.idFront.replace(/\n/g, '')
      //     uploadPhoto(
      //       this.dataURLtoFile(
      //         'data:image/jpg;base64,' + res.data.idFront,
      //         'idCardFrontUrl.jpg'
      //       ),
      //       (url) => {
      //         this.bioassay.idFront = url
      //       }
      //     )
      //     uploadPhoto(
      //       this.dataURLtoFile(
      //         'data:image/jpg;base64,' + res.data.idBack,
      //         'idCardBackUrl.jpg'
      //       ),
      //       (url) => {
      //         this.bioassay.idBack = url
      //       }
      //     )
      //   } else {
      //     this.$message.error(res.message)
      //   }
      // })
    },
    // 开户行名称
    cascader(value) {
      this.bankNum.cityCode = value[1]
    },
    next() {
      if (!this.verify()) return
      this.loading = true
      // 申请自然人
      const findItem = this.Bank.find((item) => {
        return item.bankCode === this.openBank
      })
      const config = {
        legalName: this.bioassay.name,
        idCard: this.bioassay.id,
        sex: this.bioassay.gender,
        belongNation: this.bioassay.race,
        bankAccount: this.bankAccount,
        bankReservedMobile: this.bankReservedMobile,
        purchaserId: this.purchaserId,
        zsBankCode: this.openBank,
        openBankName: this.bankName,
        bankName: findItem && findItem.bankName,
        stayAddr: this.bioassay.address,
        openBankNo: this.bankNumber,
        ...this.form,
        idCardStartDate: this.form.idCardStartDate ? moment(this.form.idCardStartDate).format('YYYY-MM-DD') : undefined,
        idCardEndDate: this.long && this.form.idCardStartDate ? '长期' : (this.form.idCardEndDate ? moment(this.form.idCardEndDate).format('YYYY-MM-DD') : undefined)
      }
      if (this.taxRegionName.indexOf('不进行工商注册') > -1) {
        config.taxRegionId = this.regionId
        personal(config).then((res) => {
          this.loading = false
          if (res.code === 200) {
            this.$store.dispatch("getuserMenu")
            this.$router.replace({
              path: '/accomplishnom',
              query: {
                id: res.data
              }
            })
          } else {
            this.$message.error(res.message)
          }
        }).catch(r=>{
          this.loading = false
          this.$message.error(r.message)
        })
        return
      }
      addindividual(config).then((res) => {
        this.loading = false
        if (res.code === 200) {
          this.$router.replace({
            path: '/applyshop',
            query: {
              bizNo: this.$route.query.bizNo,
              commercialName: this.commercialName,
              relatedPurchaserId: this.purchaserId,
              regionId: this.regionId,
              taxRegionName: this.taxRegionName,
              belongNation: res.data.belongNation,
              bankReservedMobile: this.bankReservedMobile,
              individualId: res.data.individualId
            }
          })
        } else {
          this.$message.error(res.message)
        }
      }).catch(r => {
        this.loading = false
        this.$message.error(r.message)
      })
    },
    // 验证
    verify() {
      if (!this.form.idCardFrontUrl) {
        this.$message.error('请先上传身份证的正面进行识别')
      } else if (!this.bioassay.id || !this.bioassay.name || this.bioassay.gender === undefined || !this.bioassay.address || !this.bioassay.race) {
        this.$message.error('请补全身份信息')
      } else if (!this.form.idCardBackUrl) {
        this.$message.error('请上传身份证的反面')
      } else if (!this.form.idCardHeadUrl) {
        this.$message.error('请上传头像')
      } else if (this.form.idCardStartDate && !this.long && !this.form.idCardEndDate) {
        this.$message.error('请填写正确的身份证有效期')
      } else if (!this.taxRegionName) {
        this.$message.error('请选择税区')
      } else if (!this.bankAccount) {
        this.$message.error('请输入银行账号')
      } else if (!this.openBank) {
        this.$message.error('请选择银行')
      } else if (!myreg.test(this.bankReservedMobile)) {
        this.$message.error('请填写正确的手机号')
      } else return true
    },
    onIndateChange(m, e) {
      this.form.idCardStartDate = e[0]
      this.form.idCardEndDate = e[1]
    },
    // 上传img文件
    getImgFile(e) {
      if (e.target.files[0].type.indexOf('image') === -1) {
        this.$message.error('请上传正确的格式')
        e.target.value = null
        return
      }
      if (this.uploadLabel === 'customUpload') {
        this.idCardHeadSwitch = false
        this.loadingHeader = true
        uploadPhoto(Array.from(e.target.files)[0], (url) => {
          this.loadingHeader = false
          this.form.idCardHeadUrl = url
        })
        e.target.value = null
        return
      }
      this.idCardLoading = true
      uploadPhoto(Array.from(e.target.files)[0], (url) => {
        this.idCardLoading = false
        this.form[this.uploadLabel] = url
        if (this.uploadLabel === 'idCardFrontUrl') {
          cardOcr(url).then(res => {
            console.log(res.data);
            this.bioassay.id = res.data.idNumber
            this.bioassay.name = res.data.name
            this.bioassay.gender = res.data.sex === '男' ? 1 : 0
            this.bioassay.address = res.data.address
            this.bioassay.race = res.data.ethnicity
          })
        }
        if (this.uploadLabel === 'idCardBackUrl') {
          cardBackOcr(url).then(res => {
            console.log(res.data);
            this.form.idCardStartDate = res.data.startDate
            if (res.data.endDate === '长期') {
              this.long = true
              this.form.idCardEndDate = undefined
            } else {
              this.long = false
              this.form.idCardEndDate = res.data.endDate
            }
          })
        }
      })
      e.target.value = null
    },
    uploadIdCard(e) {
      this.uploadLabel = e
      this.$refs.addImage.dispatchEvent(new MouseEvent('click'))
    },
    realTime(data) {
      data.w &&
        this.$refs.cropper.getCropData((data) => {
          this.idCardHeadBase64 = data
        })
    },
    tailor() {
      if (!this.form.idCardFrontUrl) {
        this.$message.error('请先上传身份证的正面，再进行裁剪')
        return
      }
      this.idCardHeadSwitch
        ? uploadPhoto(this.dataURLtoFile(this.idCardHeadBase64, 'seal.png'), (url) => {
          this.idCardHeadSwitch = false
          this.form.idCardHeadUrl = url
        })
        : (this.idCardHeadSwitch = true)
    },
    disabledStartDate (current) {
      return current && this.form.idCardEndDate && current > moment(this.form.idCardEndDate)
    },
    disabledEndDate (current) {
        return current && this.form.idCardStartDate && current < moment(this.form.idCardStartDate)
    },
  }
}
</script>

<style lang="less" scoped>
.perfectInfo {
  max-width: 1440px;
  min-width: 990px;
  @media screen and (max-width: 1440px) {
    padding: 0 15px;
  }
  margin: 60px auto;
  background: #ffffff;
  .header-title {
    padding: 30px 50px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.847058823529412);
    font-weight: 650;
  }
  .IDinformation {
    width: 100%;
    position: relative;
    .information {
      width: 820px;
      text-align: right;
      padding: 30px 0;
      .list {
        padding: 10px;
      }
      .info-input {
        display: inline-block;
        width: 496px;
        // @media screen and (max-width: 1440px) {
        //   width: 400px;
        // }
        background-color: #ffffff;
      }
      .activate-input {
        background-color: rgba(245, 245, 245, 1);
      }
    }
    .tax {
      display: flex;
      .tax-input {
        width: 820px;
        text-align: right;
      }
      .tax-text {
        padding: 0 0 0 40px;
      }
      .info-input2 {
        width: 340px;
        margin-top: 20px;
        @media screen and (max-width: 1440px) {
          width: 240px;
        }
        height: 30px;
      }
      p {
        line-height: 20px;
        font-size: 14px;
        padding: 30px 0 0 0;
      }
      .tax-text {
        display: inline-block;
        max-width: 480px;
        font-size: 14px;
      }
    }
    .bankverify {
      .pt10 {
        padding: 20px !important;
        font-size: 14px;
      }
      .banktitle {
        padding: 50px 80px 0 110px;
        display: flex;
        justify-content: space-between;
        .banktitle-left {
          font-size: 20px;
          font-weight: 650;
          color: rgba(0, 0, 0, 0.847058823529412);
        }
        .banktitle-right {
          width: 440px;
          font-size: 14px;
          line-height: 20px;
        }
      }
      .verification {
        .carefully {
          text-align: left;
          padding: 20px 0 0 90px;
          color: rgb(246, 0, 0);
          font-size: 14px;
        }
        padding: 0 0 0 220px;
        p {
          padding: 20px 0 0 0;
          font-size: 14px;
          // margin-left: 40%;
          width: 755px;
          text-align: left;
        }
        .p-bank-bind {
          width: 820px;
          @media screen and (max-width: 990px) {
            width: 755px;
          }
          .p-bank {
            width: 200px;
            @media screen and (max-width: 990px) {
              width: 154px;
            }
          }
          .p-site {
            width: 180px;
            @media screen and (max-width: 990px) {
              width: 160px;
            }
          }
        }
        .info-input3 {
          width: 440px;
          height: 30px;
          padding: 0 0 0 10px;
        }
      }
    }
    .next {
      display: flex;
      padding: 50px 110px 55px 50px;
      color: #ffffff;
      justify-content: center;
    }
  }
}
.activate-input {
  background-color: rgba(245, 245, 245, 1);
}
.SearchSelect {
  display: inline-block;
  width: 230px;
  @media screen and (max-width: 1440px) {
    width: 230px;
  }
}
.search-bank-v {
  width: 300px;
}
.canve {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 120px;
  height: 150px;
  overflow: hidden;
  position: relative;
  img {
    position: absolute;
    right: -8px;
    top: -25px;
  }
}
.red {
  font-size: 14px;
  color: red;
}

.upload-content {
  margin-right: 165px;
  .upload {
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    border: 1px dashed #1890ff;
    cursor: pointer;
    display: inline-block;
    width: 365px;
    height: 205px;
    font-weight: 700;
    color: #1890ff;
    font-size: 40px;
    text-align: center;
    line-height: 205px;
  }
  .card {
    cursor: pointer;
    width: 349px;
    height: 214px;
  }
  .hasUrl {
    border-radius: 19px;
    border: 2px solid #d0dcef;
    object-fit: contain;
  }
}
.company {
  display: inline-block;
  width: 496px;
  text-align: left;
  // @media screen and (max-width: 1440px) {
  //   width: 400px;
  // }
}
.container {
  display: inline-block;
  width: 496px;
  text-align: left;
  // @media screen and (max-width: 1440px) {
  //   width: 400px;
  // }
}
.cropper {
  height: 180px !important;
  width: 206px !important;
  display: inline-block;
  vertical-align: bottom;
  margin-right: 16px;
}
.note {
  color: red;
  line-height: 24px;
}
.serve-row {
  padding: 36px 0 0 108px;
  .serve-select {
    width: 220px;
  }
}
.radio-chunk {
    display: inline-block;
    height: 72px;
    vertical-align: middle;
    background-color: rgba(242, 242, 242, 1);
    border: none;
    border-radius: 5px;
    padding: 0 20px;
    line-height: 72px;
    user-select: none;
    width: 826px;
    font-size: 16px;
}
/deep/ .ant-radio-wrapper-checked {
  .radio-chunk {
    border: 1px solid @primary;
    background-color: @primary;
    color: #fff;
  }
}
</style>