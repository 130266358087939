<template>
     <div class="schedule">
        <a-steps direction="vertical" size="small" :current="steps">
          <a-step title="1.个人信息" description="" />
          <template v-if="type !== 2">
            <a-step title="2.工商申请表" description="" />
            <a-step title="3.等待办理" description="" />
          </template>
          <template v-else>
            <a-step title="2.完成注册" description="" />
          </template>
        </a-steps>
      </div>
</template>

<script>
export default {
   props:['steps', 'type']
}
</script>
<style lang="less" scoped>
    .schedule {
      max-width: 135px;
      position: absolute;
      top: 120px;
      right: 20px;              
      .ant-steps-vertical {
        /deep/.ant-steps-item-content {
          height: 100px;
        }
      }
    }
</style>